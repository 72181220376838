/* Comment to workaround CSS ordering bug (https://github.com/vercel/next.js/issues/16630#issuecomment-1037305875) */
@import '../../../libs/component-library/src/global-styles.css';
@import '@aws-amplify/ui-react/dist/styles.css';

/* Patch to remove style in amplify stylesheet */
body {
  min-height: auto;
}

#__next {
  @apply h-full;
}

:root {
  --amplify-colors-background-primary: var(--white-pure);
  --amplify-colors-background-secondary: var(--grey-6);
  --amplify-colors-font-primary: var(--grey-1);
  --amplify-colors-brand-primary-10: var(--teal-light);
  --amplify-colors-brand-primary-100: var(--teal-dark);
  --amplify-colors-brand-primary-90: var(--coral-med);
  --amplify-colors-brand-primary-80: var(--coral-dark);
  --amplify-colors-red-80: var(--error-light);
  --amplify-colors-red-90: var(--error-dark);
  --amplify-colors-red-100: var(--error-dark);
  --amplify-colors-border-error: var(--error-dark);
}

.global-auth-wrapper {
  background-color: var(--white-pure) !important;
}

.global-auth-wrapper:before {
  content: '';
  background-color: var(--white-pure) !important;
  background: url('../public/images/logo.png') no-repeat center;
  background-size: 200px;
  background-position: 50% 0.5em;
  height: 5rem;
}

@media (min-width: 768px) {
  .global-auth-wrapper:before {
    background-position: 0.5rem 0.5rem;
  }
}

.global-auth-wrapper .amplify-button {
  border-radius: 2em;
}

.global-auth-wrapper.sign-in button.amplify-button {
  display: none;
}

.global-auth-wrapper .amplify-passwordfield {
  display: none;
}

.global-auth-wrapper .amplify-field__show-password {
  border-radius: 0;
}

select:required:invalid {
  color: gray;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
}

.amplify-tabs {
  display: none;
}

.amplify-button[data-variation='primary'] {
  background: var(--coral-med);
  border: none;
  box-shadow: none;
  color: var(--white-pure);
}

.amplify-button[data-variation='primary']:disabled {
  background: var(--grey-5);
  color: var(--grey-3);
  box-shadow: inset 0 5px 4px 0 rgba(0, 0, 0, 0.05);
}

[data-amplify-footer] {
  display: none;
}

.amplify-button--link:active {
  background-color: transparent;
}

.amplify-button--link:focus {
  background-color: transparent;
  color: var(--coral-dark);
}

.amplify-button:focus {
  box-shadow: none;
}

.amplify-button--link:hover {
  background-color: transparent;
}

.amplify-tabs-item:focus {
  color: var(--coral-dark);
}

.amplify-button--link:visited {
  background-color: transparent;
}

/* SCROLLBAR STYLES */
/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #959a9d;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cacdce;
}

#react-phone-number-input {
  border-width: 0px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#react-phone-number-input::placeholder {
  color: #cacdce;
}
